.distribute-page {
    margin-top: 96px;
    padding-left: 144px;
    padding-right: 144px;
    .distribute-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        & > :first-child {
            h1 {
             width: 80%;
                color: white;
                font-size: 64px;
                font-weight: 600;
                font-family: 'Satoshi';
            }
            p {
                width: 80%;
                margin-top: 24px;
                text-align: left;
                
                color:#B5B3BC;
                font-size: 20px;
                font-family: 'Satoshi';
            }
            p2 {
                color:#B5B3BC;
                font-size: 64px;
                font-family: 'Optien';
            }
        }

            .signup-panel {
                width: 75%;
                border: 1px solid #323232;
                // background-color: #121214;
                display: inline-table;
                background-color: #121214;
                display: table;
                border-radius:24px ;
                padding: 24px;
                font-family: 'Satoshi';
                h1 {
                    font-size: 24px;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-align: center;
                    margin-bottom: 16px;
                }
                .input-panel {
                    width: 100%;
                    margin:8px 0 8px 0;
                    input {
                        width: 100%;
                        border: 1px solid #323232;
                        color:#B5B3BC;
                        font-weight: 500;
                        font-family: 'Satoshi';
                        padding:16px;
                        background-color: transparent;
                        border-radius: 27px;
                        margin : 8px 0 8px 0;
                    }
                }

                p {
                    margin:16px 0 0 0;
                    color: white;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    font-family: 'Satoshi';
                }
                &  button, a{
                    
                    min-width: 185px;
                    border: 3px solid #0A35C2;
                    padding:16px 20px 16px 20px;
                    background-color: transparent;
                    outline: none;
                    color: white;
                    font-size: 16px;
                    font-weight: 900;
                    font-family: 'Satoshi';
                    border-radius: 100px;
                    width: 100%;
                }
                & a {
                    text-align: center;
                }
                .create-account {
                    margin:16px 0 16px 0;
                }
                .signup-google-facebook {
                    margin-top: 32px;
                    display: grid;
                    & > :first-child {
                        margin-bottom: 16px;
                    }
                }
        }
    }
    .distribute-center {
       
        margin-top: 137px;
        img {
            width: 447px;
            height: 447px;
        }
        div {
            max-width: 586px;
            margin-left: 120px;
            h1 {
                color:white;
                font-size: 48px;
                font-weight: 600;
            }
            p{
                margin-top: 24px;
                font-family: 'Satoshi';
                color:#B5B3BC;
                font-size: 16px;
            }
        }
    }
    .distribute-music {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-top: 217px;
        & > :nth-child(2) {
            width: 540px;
             h1 {
                color:white;
                font-size: 48px;
                font-weight: 600;
            }
            p {
                margin-top: 24px;
                font-family: 'Satoshi';
                color:#B5B3BC;
                font-size: 16px;
            }
        }
        .music-icon-panel {
            margin-left: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            & img {
                width: 135px;
                height: 135px;
            }
            div {
                width: fit-content;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 32px;
            }
            & :nth-child(2) {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
    .distribute-faq {
        margin-top: 100px;
        padding-top: 104px;
        text-align: center;
        h1 {
            font-family: 'Satoshi';
            text-align: center;
            font-size: 48px;
            font-weight: 600;
            color:#F2F0FF
        }

        p2 {
                color:#B5B3BC;
                font-size: 48px;
                font-family: 'Optien';
            }

        ul {
            list-style: none;
            color : #B5B3BC;
            padding:0;
            margin: 80px 96px 179px 96px;
            li {
                & > :first-child {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 16px;
                    padding : 20px 8px 20px 8px;
                    border-bottom: 1px solid #323232;
                    font-weight: 500;
                    font-size: 20px;
                    & > :nth-child(2) {
                        cursor: pointer;
                    }
                }
                .sub-drop {
                    border-width: 2px 0px 2px 0px;
                    font-weight: 500;
                    border-style: solid;
                    border-color: #E4E4E7;
                    font-size: 18px;
                    padding: 24px 8px 24px 8px;
                    margin:0;
                    text-align: left;
                }
                .active-faq {
                    font-weight: 700;
                    color: white;
                }
            }
        }
    }
}

@media screen and (max-width:900px) {
    .distribute-page {
        .distribute-center {
            div {
                margin-left: 50px;
            }
        }
        .distribute-music {
            display: block;
            & > :nth-child(2) {
                width: fit-content;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .distribute-page {
        margin-top: 56px;
        padding-left: 16px;
        padding-right: 16px;
        .distribute-header {
            text-align: center;
            display: block;
            justify-content: space-between;
            & > :first-child {
                width: fit-content;
                text-align: center;
                h1 {
                    text-align: center;
                    font-size: 48px;
                }
                p {
                    width: 100%;
                    text-align: center;
                    margin-top: 16px;
                    font-size: 14px;
                }
            }

            .signup-panel {
                width: 90%;
                margin-top: 64px;
                h1 {
                    font-size: 20px;
                }
                .input-panel {
                    input {
                        font-size: 14px;
                    }
                }
            }
        }
        .distribute-center {
            text-align: center;
            display: block;
            margin-top: 88px;
            img {
                width: 340px;
                height: 340px;
            }
            div {
                width: 100%;
                margin:auto;
                h1 {
                    font-size: 32px;
                }
                p{
                    margin-top: 16px;
                    font-size: 14px;
                }
            }
        }
        .distribute-music {
            display: block;
            align-items: center;
            margin-top: 50px;
            & > :nth-child(2) {
                width: fit-content;
                text-align: center;
                h1 {
                    font-size: 32px;
                }
                p {
                    margin-top: 16px;
                    font-size: 14px;
                }
            }
            .music-icon-panel {
                gap: 24px;
                & img {
                    width: 96px;
                    height: 96px;
                }
                div {
                    gap: 24px;
                }
            }
        }
        .distribute-faq {
            margin-top: 80px;
            padding-top: 56px;
            text-align: center;
            h1 {
                font-size: 32px;
            }
            ul {
                margin : 56px 0 0 0;
                li {
                    div p {
                        font-size: 14px;
                    }
                    .sub-drop {
                        font-size:14px
                    }
                } 
            }
        }
    }
}

@media screen and (max-width:420px){
    .distribute-page {
        .distribute-center {
            img {
                max-width: 340px;
                width: 90%;
                height: fit-content;
            }
            div {
                margin: 0;
            }
        }
        .distribute-music {
            .music-icon-panel {
                div {
                    display: flex;
                    flex-direction: column;
                }
            }
        }   
    } 
}