.header-container {
    display: flex;
    box-sizing: border-box;
    width: 90%;
    height: 56px;
    background-color: #000;
    border-bottom: 2px solid #121212;
    margin: auto;
    margin-top: 16px;
    padding-bottom: 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    font-family: 'Satoshi';

    .links {
        display: flex;
        align-items: center;

        .link-item {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #FFFFFF;
            margin-left: 50px;
            &.active {
                border-bottom: 2px solid blue
            }

            &:hover {
                cursor: pointer;
            }
        }

        .hamburger {
            display: none;
        }
    }
}

.bg {
        position: absolute;
        filter: blur(125px);
        background: linear-gradient(180deg, rgba(3, 104, 255, 0.2) 0.82%, rgba(88, 153, 255, 0.2) 103.35%);
        width: 60%;
        height: 60%;
        left: 20%;
        top: 20%;
    }

.ham-dropdown {
    display: none;
}

.footer-container {
    font-family: 'Satoshi';
    flex-shrink: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $white;
    height: 56px;
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 40px;
    margin-left: 104px;
    margin-right: 104px;
    justify-content: space-between;
    padding: 17px 40px 17px 14px;
    ul {
        display: flex;
        list-style: none;
        & > :first-child {
            display: none;
        }
        & > :nth-child(2) {
            margin-left:0;
        }
        li {
            margin-left : 72px;
            a {
                color: white;
                cursor: pointer;
            }
        }
    }
}

.card-container {
    background: #121214;
    border: 1px solid #323232;
    border-radius: 24px;
    padding: 20px;
    z-index: 1;

    .name {
        font-family: 'Optien'; 
        font-style: normal;
        font-weight: 700;
        line-height: 54px;
        font-size: 30px;
        text-transform: uppercase;
        color: $white;
        margin-top: 20px;
    }

    .description {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #B5B3BC;
        margin-top: 20px;
    }

    .button {
        padding: 16px;
        cursor: pointer;
        height: 64px;
        border-radius: 100px;
        border: solid 2px #0A35C2;
        font-family: 'Optien';
        font-weight: 900;
        font-size: 20px;
        margin: auto;
        color: $white;
        text-transform: uppercase;
        width: fit-content;
        margin-top: 50px;

        img {
            margin-right: 15px;
        }
    }
}

@media screen and (max-width:768px){
    .header-container {
        width: 100%;
        padding: 8px 16px 8px 16px;
        .links {
            .link-item {
                display: none;
            }
            .hamburger {
                display: block;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .ham-dropdown {
        display: block;
        position: absolute;
        top:72px;
        width: 100%;
        background-color: #0C0C0D;
        z-index: 5;
        ul {
            list-style: none;
            padding-top: 4px;
            padding-bottom: 4px;
            border-top: 1px solid grey;
            border-bottom: 1px solid grey;
            padding-left: 0;
            margin-bottom: 0;
            li {
                color: white;
                display: flex;
                justify-content: center;
                div {
                    margin-top: 12px;
                    margin-bottom: 12px;
                    width: fit-content;
                    a {
                        color: white;
                        font-size: 16px;
                    }
                    &.active {
                        border-bottom: 2px solid blue
                    }
                }
            }
        }
    }
    .footer-container {
        display: inline-table;
        ul {
            display: block;
            list-style: none;
            padding-left: 0;
            margin-top: 8px;
            margin-bottom: 24px;
            & > :first-child {
                display: block;
            }

            li {
                margin-left: 0;
                padding-top: 24px;
                padding-bottom: 24px;
                font-size: 16px;
                color:white
            }
        }
    }
    .card-container {
        .button {
            font-size: 18px;
            // width: 100%;
        }
        .name {
            font-size: 36px;
            // width: 100%;
        }
        .description {
            font-size: 18px;
            // width: 100%;
        }
    }
}

@media screen and (max-width:400px){
    .card-container {
        .name {
            font-size: 20px;
        }
    }
    .footer-container {
        margin: 0;
        padding: 0;
    }
}
