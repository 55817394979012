// .home-page {
//     margin-left: 104px;
//     margin-right:105px;
//     position: relative;

//     .title {
//         font-family: 'Satoshi';
//         font-style: normal;
//         font-weight: 700;
//         font-size: 50px;
//         line-height: 64.8px;
//         text-align: center;
//         color: #FFFFFF;
//         margin-top: 60px;
//     }
//     .subtitle {
//         font-family: 'Satoshi';
//         font-style: normal;
//         font-weight: 400;
//         font-size: 30px;
//         line-height: 64.8px;
//         text-align: center;
//         color: #FFFFFF;
//     }
//     .bg {
//         position: absolute;
//         filter: blur(125px);
//         background: linear-gradient(180deg, rgba(3, 104, 255, 0.2) 0.82%, rgba(88, 153, 255, 0.2) 103.35%);
//         width: 60%;
//         height: 60%;
//         left: 20%;
//         top: 20%;
//     }

//     .cards {
//         margin-top: 80px;
//         justify-content: space-between;
//         display: grid;
//         grid-template-columns: repeat(3, 1fr);
//         gap: 32px;
//         .card-container {
//             font: 20px;
//             .card_img {
//                 height: 104px;
//             }
//             .button {
//                 display: flex;
//                 min-width: 100px;
//                 .button_img {
//                     width: 32px;
//                     height: 32px;
//                 }
//             }
//         }
//     }
// }

// .signup-panel {
//     width: 70%;
//     border: 1px solid #323232;
//     // background-color: #121214;
//     display: inline-table;
//     border-radius:24px ;
//     padding: 24px;
//     font-family: 'Satoshi';
//     h1 {
//         font-size: 24px;
//         font-weight: 600;
//         color: #FFFFFF;
//         text-align: center;
//         margin-bottom: 16px;
//     }
//     .input-panel {
//         margin:8px 0 8px 0;
//         input {
//             width: 100%;
//             border: 1px solid #323232;
//             color:#B5B3BC;
//             font-weight: 500;
//             font-family: 'Satoshi';
//             padding:16px;
//             background-color: transparent;
//             font-size: 16px;
//             line-height: 21.6px;
//             height: fit-content;
//             border-radius: 27px;
//             margin : 8px 0 8px 0;
//             outline: none;
//             box-sizing: border-box;
//         }
//     }

//     p {
//         margin:16px 0 0 0;
//         color: white;
//         text-align: center;
//         font-size: 16px;
//         font-weight: 600;
//         font-family: 'Satoshi';
//     }
//     &  button, a{
        
//         min-width: 185px;
//         border: 3px solid #0A35C2;
//         padding:16px 20px 16px 20px;
//         background-color: transparent;
//         outline: none;
//         color: white;
//         font-size: 16px;
//         font-weight: 900;
//         font-family: 'Satoshi';
//         border-radius: 100px;
//         width: 100%;
//     }
//     & a {
//         text-align: center;
//     }
//     .create-account {
//         margin:16px 0 16px 0;
//     }
//     .signup-google-facebook {
//         margin-top: 32px;
//         display: grid;
//         & > :first-child {
//             margin-bottom: 16px;
//         }
//     }
// }

// @media screen and (max-width:1104px) {
//     .home-page .cards{
//         width: fit-content;
//         margin-left: auto;
//         margin-right: auto;
//         grid-template-columns: repeat(1, 1fr);
//         .card-container {
//             width: 350px;
//             .button {
//                 min-width: auto;
//             }
//             .card_img {
//                 height: 104px;
//             }
//         }
//     }
// }


// @media screen and (max-width:768px){
//     .home-page {
//         margin-left: 16px;
//         margin-right:16px;
//         .title {
//             font-size: 48px;
//         }
//         .cards {
//             display: grid;
//             grid-template-columns: repeat(1, 1fr);
//             gap: 32px;
//             .card-container {
//                 width: 100%;         
//                 .card_img {
//                     width: 88px;
//                     height: 88px;
//                 }
//                 .button {
//                     height: fit-content;
//                     min-width: auto;
//                     .button_img {
//                         width: 24px;
//                         height: 25px;
//                     }
//                 }

//             }
//         }
//     }
//     .signup-panel {
//         width: 100%;
//     }
// }

// @media screen and (max-width:400px) {
//     .home-page .cards .card-container .card_img {
//         width: 88px;
//         height: 88px;
//     }
// }

.home-banner {
    width: 100%;
    min-height: calc(100vh - 72px);
    background-image: url(../assets/banner-bg.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}

.banner-title {
    font-size: 2.5rem;
    color: #fff;
    font-family: 'Satoshi';
    font-weight: 600;
    line-height: 2.2rem;
}

.banner-subtitle {
    color: lightgray;
    font-family: 'Satoshi';
    font-size: 1.5rem;
    margin-top: 15px;
    margin-bottom: 25px;
}

.banner-subtitle span {
    color: #337CCF;
}

.banner-btn {
    height: 50px;
    width: fit-content;
    // border: 1px solid #fff;
    color: #fff !important;
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 0 20px;
    border-radius: 30px;
    background: linear-gradient(to right, #337CCF, #16375c);
    font-family: 'Satoshi';
    transition: .5s;
}

.banner-btn img {
    height: 18px;
    margin-bottom: -2px;
    margin-left: 20px;
}

.banner-btn:hover {
    border-radius: 3px;
}

.s-card {
    width: 100%;
    min-height: 300px;
    border-radius: 20px;
    padding: 20px;
    background-size: cover;
    background-position: center;
    background-color: #00000056;
    background-blend-mode: darken;
    color: #fff;
    font-family: 'Satoshi'; 
    overflow: hidden;  
    box-shadow: 0px -42px 47px 18px rgba(0,0,0,1) inset;
    display: flex;
    align-items: flex-end;
    transition: .5s;
}

.s-card:hover {
    box-shadow: none;
}

.s-content {
    height: fit-content;
    width: 100%;
}

.s-content h1 {
    margin-bottom: 0;
    font-size: 2.2rem;
}

.s-content p {
    margin-bottom: 10px !important;
}

.s-btn {
    height: 40px;
    width: fit-content;
    color: #fff !important;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-radius: 30px;
    background: linear-gradient(to right, #337ccf69, #16375c60);
    font-family: 'Satoshi';
    transition: .5s;
    font-size: .9rem;
    backdrop-filter: blur(5px);
}

.s-btn img {
    height: 18px;
    margin-bottom: -2px;
    margin-left: 10px;
}

.s-btn:hover {
    border-radius: 3px;
}

.signup-section {
    width: 100%;
    background-color: #00000094;
    background-image: url(../assets/form-bg.jpg);
    background-blend-mode: darken;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.signup-form {
    width: 100%;
    padding: 15px;
    border-radius: 20px;
    background-color: #ffffff10;
    backdrop-filter: blur(10px);
}

.signup-form h1 {
    width: 100%;
    text-align: center;
    font-family: 'Satoshi';
    color: #fff;
    font-size: 2rem;
}

.signup-form input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    background-color: #ffffff1a;
    outline: none;
    border: none;
    color: #fff;
    border-radius: 10px;
    margin-top: 10px;
    font-family: 'Satoshi';
    transition: .5s;
}

.signup-form input:focus {
    background-color: #ffffff2c;
}

.signup-form input::placeholder {
    color: #c5c5c5;
}

.signup-form button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-top: 10px;
    border: none;
    outline: none;
    background: linear-gradient(to right, #337ccf69, #16375c60);
    color: #fff;
    font-family: 'Satoshi';
}

