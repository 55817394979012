.playlist-page {
    margin-left:40px;
    margin-right: 40px;
}
.title {
    margin-top: 80px;
    text-align: center;
    font-size: 72px;
    color: white;
    font-weight: 700;
    font-family: 'Satoshi';

    p2 {
    color:#B5B3BC;
    margin-top: 80px;
    text-align: center;
    font-size: 72px;
    font-weight: 700;
    font-family: 'Optien';
    }
}

.c-banner {
    min-height: fit-content;
    padding: 60px 0;
    background-image: url(../assets/playlists-banner.jpg);

    .banner-title span {
        font-family: 'Optien';
        color:#B5B3BC;
    }

    .banner-subtitle {
        font-size: 1.3rem;
    }
}

.list-text {
    font-size: 20px;
    display: flex;
    justify-content: center;
    color: white;
    p {
        width: 70%;
        text-align: center;
        font-family: 'Satoshi';
    }
}
.list-container {
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;

    .list-item {
        border :1px solid #323232;
        border-radius: 24px;
        width: 100%;
        height: 631px;
        .list-header {
            display: flex;
            margin:  24px 24px 0 24px;
            .list-avatar {
                width: 160px;
                height: 160px;
            }
            .list-item-info-panel {
                flex: 1;
                margin-left: 14px;
                .shopify-panel {
                    display: flex;
                    justify-content: flex-end;
                }
                .list-item-info {
                    margin-top: 21px;
                    & > :first-child {
                        color:white;
                        margin-bottom:6px;
                        font-size: 20px;
                        font-weight: 700;
                        font-family: 'Satoshi';
                    }
                    & > :nth-child(2) {
                        color:#B5B3BC;
                        margin: 0;
                        font-weight: 400;
                        font-size: 16px;
                    }
                }
                .play-panel {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 10px;
                }
            }
        }
        .list-control {
            margin:  10px 24px 21px 24px;
            display: flex;
            .preview {
                padding: 8px;
                background-color: #0A35C2;
                color: white;
                font-weight: 700;
                font-size: 16px;
                font-family: 'Satoshi';
                border-radius: 24px;
                width: fit-content;
            }
            .control-panel {
                margin-left: 67px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 32px;
            }
        }
        .list-record {
            border-top: 1px solid #323232;
            padding: 24px 24px 0 24px;
            height: 365px;
            overflow: hidden;
            ul {
                list-style: none;
                padding: 0;
                li {
                    display: flex;
                    justify-content: space-between;
                    color: white;
                    align-items: center;
                    .record-left {
                        display: flex;
                        align-items: center;
                        .record-number {
                            font-size: 18px;
                            margin-right: 16px;
                            margin-bottom: 0;
                            font-weight: 700;
                        }
                        .record-name-panel {
                            & > :first-child {
                                font-family: 'Satoshi';
                                margin-bottom: 4px; ;
                                font-size: 18px;
                                font-weight: 700;
                            }
                            & > :nth-child(2) {
                                font-family: 'Satoshi';
                                font-size: 14px;
                                font-weight: 500;
                                color: #B5B3BC;
                                margin:0
                            }
                        }
                    }
                    .time {
                        margin: 0;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    & * {
        width: fit-content;
        margin-left:8px;
        margin-right: 8px;
        cursor: pointer;
    }
    ul {
        list-style: none;
        display: flex;
        padding:0;
        
        .active {
            color: white;
            border-bottom: 1px solid blue;
        }

        li {
            color: #B5B3BC;
            font-family: 'Satoshi';
            font-size: 16px;
            font-weight: 500;
        }
    }
}
.add-playlist {
    margin-top: 80px;
    padding : 40px 0px 45px 40px;
    display: flex;
    justify-content: space-between;
    .add-playlist-left {
        h1 {
            color: white;
            font-size: 56px;
            font-family: sans-serif;
            font-weight: 600;
            font-family: 'Satoshi';
        }
        .input-panel {
            display: flex;
            margin-top: 27px;
            font-family: 'Satoshi';
            input {
                width: 100%;
                border: 1px solid #323232;
                height: 54px;
                padding: 16px;
                color:#B5B3BC;
                font-size: 16px;
                background-color: transparent;
                border-radius: 100px;
                outline: none;
            }
            button {
                margin-left: 16px;
                border: 3px solid blue;
                color: white;
                background-color: transparent;
                border-radius: 32px;
                padding: 16px;
                font-size: 16px;
                font-weight: 900;
                outline: none;
            }
        }
    }
    .add-playlist-right {
        padding-top: 86px;
        .circle {
            width: 280px;
        }
    }

}

@media screen and (max-width:1151px) {
    .add-playlist {
        display: block;
    }
}

@media screen and (max-width:768px) {
    .title {
        font-size: 48px;
    }
    .list-text {
        font-size: 16px;
    }

    .list-container {
        margin-top: 56px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 32px;

        .list-item {
            border :1px solid #323232;
            border-radius: 24px;
            width: 100%;
            height: 396px;
            .list-header {
                display: flex;
                margin:  16px 16px 0 16px;
                .list-avatar {
                    width: 128px;
                    height: 128px;
                }
                .list-item-info-panel {
                    flex: 1;
                    margin-left: 14px;

                    .list-item-info {
                        margin-top: 6px;
                    }
                    .play-panel {
                        margin-top: -5px;
                    }
                }
            }
            .list-control {
                margin:  11px 16px 12px 16px;
                .preview {
                    font-size: 14px;
                }
            }
            .list-record {
                border-top: 1px solid #323232;
                padding: 16px 16px 0 16px;
                height: 198px;
            }
        }
    }
    .list-container .list-item .list-record ul li .record-left {
        .record-number {
            font-size: 16px;
        }
        .record-name-panel {
            & > :first-child {
                font-size: 16px;
            }
            & > :nth-child(2) {
                font-size: 14px;
            }
        }
    }
    .pagination {
        margin-top: 32px;
        ul li {
            font-size: 14px;
        }
    }
    .add-playlist {
        margin-top: 56px;
        padding : 0;
        display: inherit;
        .add-playlist-left {
            h1 {
                font-size: 32px;
            }
            .input-panel {
                display: inline-grid;
                margin-top: 24px;
                width: 100%;
                input {
                    font-size: 14px;
                }
                button {
                    width: fit-content;
                    margin-top: 16px;
                    margin-left: 0;
                    font-size: 14px;
                }
            }
        }
        .add-playlist-right {
            padding-top:0;
            .circle {
                float: right;
                margin-top: -20px;
                width: 138px;
                height: 70px;
            }
        }

    }
}

@media screen and (max-width:250px) {
    .playlist-page {
        margin: 0;
    }
    .footer-container {
        margin: 0;
    }
}